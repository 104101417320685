import React, { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { postFile } from "../../../../utils/requests/candidatures";
import LoadingSpinner from "../../../Animation/LoadingSpinner";
import { Button } from "../../../catalyst-component/button";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { VariablesContext } from "../../../../auth/AppProvider";

function FileUpload({ value, msalInstance, id, formId, dealId, contactId, questionId, t, onChange, ...newProps }) {
  const variables = useContext(VariablesContext);
  const [restartSubmission, setrestartSubmission] = useState(false);
  const [loadingUploadDoc, setLoadingUploadDec] = useState("");
  const [error, setError] = useState("")

  useEffect(() => {
    if (!(value && !restartSubmission)) {
      var elDragDrop = document.getElementById("drag-and-drop-" + id);
      if (window.File && window.FileReader && window.FileList && window.Blob) {
        elDragDrop.addEventListener("dragover", (event) => {
          event.stopPropagation();
          event.preventDefault();
          event.dataTransfer.dropEffect = 'copy';
        });
        elDragDrop.addEventListener("drop", (event) => {
          event.stopPropagation();
          event.preventDefault();
          onChange(event.dataTransfer.files[0])
        });
      }
    }
  }, [value, restartSubmission, id, onChange])

  function uploadDocument(file) {
    setLoadingUploadDec("waiting");
    if (file.size > 2000000) {
      setError(t("File size cannot exceed more than 2MB"))
      setLoadingUploadDec("nok");
      setrestartSubmission(true)
    }
    else {
      postFile(msalInstance, variables, file, formId, dealId, contactId, questionId).then(res => {
        if (res.ok) {
          setrestartSubmission(false)
          setLoadingUploadDec("ok");
          return res.json();
        } else {
          setLoadingUploadDec("nok");
        }
      }).then(doc => {
        onChange(doc.url);
      })
    }

  }

  return (
    <div className="mt-2 sm:mt-0 sm:col-span-2">
      <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md" id={"drag-and-drop-" + id}>
        {loadingUploadDoc === "waiting" ?
          <LoadingSpinner /> :
          <div className="space-y-1 text-center">
            {value && !restartSubmission ? <div className="flex justify-center w-full pb-4"><CheckCircleIcon className="text-green-500 h-6 w-6" /></div> : <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
              <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>}
            {value && !restartSubmission ?
              <div className="text-sm text-gray-600 mt-6">
                <Button type="button" onClick={() => { window.open(value, '_blank'); }}>
                  {t("Download uploaded file")}
                </Button>
                <div className="relative block w-full text-center text-gray-500 hover:text-blue-600 mt-1 text-sm">
                  <button type="button" onClick={() => { setrestartSubmission(true) }}>{t("Reupload")}</button>
                </div>
              </div>
              :
              <p className="text-xs text-gray-500">
                <label htmlFor={id ? id : "file-upload"} className="relative cursor-pointer bg-white rounded-md font-small hover:text-indigo-500">
                  <div className="text-gray-500">{value && !restartSubmission ? t("Modify") : t("Upload a file")}</div>
                  <div className={error ? "text-red-600" : ""}>{error ? error : t("Max size : 2MB")}</div>
                  <input id={id ? id : "file-upload"}
                    name={id ? id : "file-upload"}
                    type="file"
                    className="hidden sr-only"
                    onChange={(event) => uploadDocument(event.target.files[0])}
                    {...newProps}></input>
                </label>
                {/* {value && <div className="relative block w-full text-center text-gray-500 hover:text-blue-600 mt-1 text-sm">
                <button type="button" onClick={() => { setrestartSubmission(false) }}>{t("Cancel")}</button>
              </div>} */}
              </p>}
          </div>}
      </div>
    </div >)
}

export default withTranslation()(FileUpload);
