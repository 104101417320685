import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router";
import { Button } from "../catalyst-component/button";
import { Badge } from "../catalyst-component/badge";
import { Text } from "../catalyst-component/text";

function CandidatureCard({ candidature, candidatureId, stage, cantBeUpdated }) {
    let navigate = useNavigate();

    return <div key={candidatureId} className={classNames(cantBeUpdated ? "bg-gray-100 border" : "bg-white shadow-md", " rounded-md  pb-6")}>
        <div className="flex-auto px-4 sm:px-6 pt-4 sm:pt-6">
            <div className="flex items-center sm:items-start">
                <div className="flex-1 text-sm">
                    <div className="font-medium text-gray-900 sm:flex sm:justify-between">
                        <div className="text-lg flex justify-center">{candidature.parameters ? candidature.parameters?.find(p => p.name === "name")?.value : "-"}
                            <div className="sm:flex sm:justify-between ml-3">
                                <div className="hidden sm:flex gap-2">
                                    {candidature.parameters?.filter(p => p.name !== "name" && p.name !== "description" && p.value !== null && p.value !== ""  && p.display).slice(0, 3).map((p,k) => <Badge key={k} color="indigo" className="px-2 text-xs uppercase">{p.value}</Badge>)}
                                </div>
                            </div>
                        </div>
                        <Badge color={cantBeUpdated ? "amber" : "emerald"} className="hidden sm:inline-flex text-center items-center rounded-full px-3 py-0.5 text-sm font-medium">
                            {stage}
                        </Badge>
                    </div>
                    {!cantBeUpdated && candidature.parameters?.find(p => p.name === "description").value ?
                        <div className="w-full h-20 overflow-hidden flex text-ellipsis relative hidden  sm:block">
                            <Text className="sm:mt-2 mr-4">{candidature.parameters ? candidature.parameters?.find(p => p.name === "description")?.value : ""}</Text>
                            <div className="absolute from-white w-full h-8 bottom-0 bg-gradient-to-t " />
                        </div> : null}
                </div>
            </div>
        </div>
        {!cantBeUpdated && <div className="flex justify-center pt-2">
            <Button className="cursor-pointer" onClick={() => !cantBeUpdated ? navigate("/candidatures/" + candidature.id) : null}>Poursuivre ma candidature</Button>
        </div>}
    </div>
}

export default (CandidatureCard);