import React, { useContext } from "react";
import { ThemeContext } from "../../App";
import { CheckIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function verifyPath(json, path) {
  var exists = true;
  if (path) {
    var steps = path.split('.');
    if (steps.length > 1) {
      steps.forEach(step => {
        if (json && json[step]) {
          json = json[step];
        } else {
          exists = false;
        }
      })
    } else {
      if (!json) {
        exists = false;
      }
    }
  } else {
    exists = false;
  }
  return exists;
}

export default function Step({ steps, currStep }) {
  const theme = useContext(ThemeContext);
  return <nav aria-label="Progress">
    <ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
      {steps.map((step, stepIdx) => (
        <li key={stepIdx} className="md:flex-1">
          {stepIdx < currStep ? (
            <div className="group pl-4 py-2 flex flex-col border-l-4 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4" style={verifyPath(theme, "secondary_color") ? { borderColor: theme.secondary_color } : null}>
              <span className="text-sm font-medium text-white" style={verifyPath(theme, "secondary_color") ? { color: theme.secondary_color } : null}>{step.name}</span>
            </div>
          ) : stepIdx === currStep ? (
            <div className="pl-4 py-2 flex flex-col border-l-4 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4" aria-current="step" style={verifyPath(theme, "secondary_color") ? { borderColor: theme.secondary_color } : null}>
              <span className="text-sm font-medium text-white">{step.name}</span>
            </div>
          ) : (
            <div
              className="group pl-4 py-2 flex flex-col border-l-4 border-white md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
            >
              <span className="text-sm font-medium text-white">{step.name}</span>
            </div>
          )}
        </li>
      ))}
    </ol>
  </nav>
}
