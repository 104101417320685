import { withTranslation } from "react-i18next"
import logoThanks from "../../fakeData/success.svg";

function SuccessPage({ t }) {
    return <div className="my-4">
        <img
            className="logo"
            src={logoThanks}
            style={{
                maxWidth: "calc(100vw - 100px)",
                maxHeight: "500px",
                height: "auto",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto"
            }}
            alt=""
        />
        <div className="text-center">
            <p className="mt-1 text-gray-500">{t("Vous avez désormais terminé de remplir votre candidature")}</p>
        </div>
    </div>
}

export default withTranslation()(SuccessPage);