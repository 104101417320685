import React from "react";
import { withTranslation } from "react-i18next";
import AllCandidatures from "./Home/AllCandidatures";

function Home({ t }) {
    return <>
        <AllCandidatures />
    </>

}

export default withTranslation()(Home);