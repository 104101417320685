import React, { useState } from "react";
import Aside from './Aside';
import { Outlet } from "react-router-dom";
import { useContext } from 'react';
import { ThemeContext } from '../../App';

export const FormTitleContext = React.createContext();

export default function Global() {
  const [formTitle, setFormTitle] = useState(null);
  const theme = useContext(ThemeContext);
  return <div className="min-h-screen bg-gray-100 typography" style={{ fontFamily: "Inter" }}>
    <FormTitleContext.Provider value={[formTitle, setFormTitle]}>
      <Aside theme={theme} />
      <div>
        <Outlet />
      </div>
    </FormTitleContext.Provider>
  </div>
}

