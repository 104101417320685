import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { withTranslation } from 'react-i18next'
import { VariablesContext } from '../../auth/AppProvider'
import { CandidaturesContext } from '../../App';

function getFilteredData(filters, listCandidatures) {
  var cands = listCandidatures;
  filters.forEach(filter => {
    const values = filter.options.filter(opt => opt.actif)
    if (values && values.length) {
      cands = cands.filter(c => values.find(v => v.value === c.parameters?.find(pa => pa.name === filter.id && pa.filter)?.value));
    }
  });
  return cands
}

function InitFormDialog({ t, returnInitForm }) {
  const [allcandidatures,] = useContext(CandidaturesContext);
  const [filteredCand, setFilteredCand] = useState([]);
  const [open, setOpen] = useState(false)
  const [filters, setFilters] = useState(null);

  const variables = useContext(VariablesContext)

  useEffect(() => {
    if (allcandidatures && allcandidatures.length) {
      setFilteredCand(allcandidatures)
      var filtersContruction
      if (localStorage.getItem("filterparameter")) {
        filtersContruction = JSON.parse(localStorage.getItem("filterparameter"))
      } else {
        filtersContruction = allcandidatures[0]?.parameters?.filter(pa => pa.filter).map(p => { return { id: p.name, name: p.label } });
        filtersContruction = filtersContruction.map(p => {
          p.options = allcandidatures.map(c => c.parameters?.find(pa => pa.name === p.id && pa.filter)?.value).filter((value, index, self) => self.indexOf(value) === index).filter(c => c !== "" && c !== null && c !== undefined).map(c => { return { value: c, label: c, actif: false } })
          return p
        }).filter(p => p?.options?.length > 1)
      }
      if (filtersContruction && filtersContruction.length > 0) {
        setOpen(true)
        setFilters(filtersContruction);
      } else {
        setOpen(false)
        returnInitForm()
      }

    }
  }, [allcandidatures]);



  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 ml-4 sm:mt-0 text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        {t("Title welcome form", { school: variables.ClientName })}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          {t("Description welcome form")}
                        </p>
                      </div>
                      <div>
                        {filters && filters.filter(f => f.id !== "name" && f.id !== "description").map((section, sectionIdx) => (
                          <fieldset className="mt-3">
                            <legend className="text-sm font-semibold leading-6 text-gray-900">{section.description ? section.description : section.name}</legend>
                            <div className="mt-2 space-y-2">
                              {section.options.filter(opt => {
                                return filteredCand.length === allcandidatures.length ? true : filteredCand.find(cand => opt.value === cand.parameters?.find(pa => pa.name === section.id)?.value)
                              })
                                .map((option, optionIdx) => (
                                  <div key={option.value} className="relative flex gap-x-3">
                                    <div className="flex h-6 items-center">
                                      <input
                                        onChange={() => {
                                          var filtersLocal = filters;
                                          var filterLocal = filters[sectionIdx];
                                          var optionId = filterLocal.options.indexOf(option)
                                          var optionLocal = filterLocal.options[optionId];
                                          optionLocal.actif = !optionLocal.actif;
                                          filterLocal.options[optionId] = optionLocal;
                                          filtersLocal[sectionIdx] = filterLocal;
                                          setFilters(filtersLocal);
                                          setFilteredCand(getFilteredData(filtersLocal, allcandidatures))
                                        }}
                                        id={`filter-${section.id}-${optionIdx}`}
                                        name={`${section.id}[]`}
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                      />
                                    </div>
                                    <div className="text-sm leading-6">
                                      <label htmlFor={`filter-${section.id}-${optionIdx}`} className="text-gray-600">{option.label}</label>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </fieldset>
                        ))}
                      </div>
                    </div>


                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 mt-2">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                    onClick={() => {
                      localStorage.setItem("filterparameter", JSON.stringify(filters))
                      setOpen(false)
                      returnInitForm()
                    }
                    }
                  >
                    {t("Enregistrer")}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default withTranslation()(InitFormDialog);