import React, { useContext } from "react";
import { withTranslation } from "react-i18next";
import { logout } from "../../auth/verifyAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { ThemeContext } from "../../App";
import { FormTitleContext } from "./Global";
import { MsalInstanceContext } from "../../auth/AppProvider";
import { Button } from '../../components/catalyst-component/button'
import { Badge } from "../../components/catalyst-component/badge";
import { BookOpenIcon } from "@heroicons/react/outline";

function Aside({ t }) {
  let navigate = useNavigate();
  let location = useLocation();
  const theme = useContext(ThemeContext);
  const [formTitle,] = useContext(FormTitleContext);

  const msalInstance = useContext(MsalInstanceContext)

  return <header className="bg-gray-100 shadow-md">
    <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
      <div className="w-full py-6 flex items-center justify-between" style={theme.primary_color ? { borderColor: theme.primary_color } : null}>
        <div className="flex items-center">
          <div className="cursor-pointer" onClick={() => {
            navigate("/");
          }}>
            <span className="sr-only">{t("Company Logo")}</span>
            {theme.header_logo ? <img
              className="h-8 w-auto"
              src={"data" + theme.header_logo?.split(";data")[1]}
              alt={theme.header_logo?.split(';')[0]}
            /> : null}
          </div>
          {formTitle && <div className="hidden ml-3 sm:flex items-center gap-2 rounded-full border border-dashed border-zinc-300 py-px pl-1 pr-3 text-xs/6 font-medium text-zinc-900">
            <div className=" px-1 h-5 w-5 rounded-full bg-zinc-200">
              <BookOpenIcon className="text-zinc-800 h-4"/>
            </div>
            {formTitle}
          </div>}
        </div>
        <div className="ml-10 space-x-4">
          <Button className="bg-zinc-500 text-white" onClick={() => {
            if (location.pathname === "/") {
              logout(msalInstance);
            } else {
              navigate("/");
              logout(msalInstance);
            }
          }}>Déconnexion</Button>
        </div>
      </div>
    </nav>
  </header>
}

export default withTranslation()(Aside);
