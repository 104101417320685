import React from "react";
import Input from "./Input";
class DatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value ? this.props.value : ""
        }
        this.onDatePickerChange = this.onDatePickerChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            this.setState({ value: this.props.value ? this.props.value : "" });
        }
    }
    onDatePickerChange(value) {
        this.props.onChange(value);
        this.setState({ value });
    }
    render() {
        var { onChange, value, ...newProps } = this.props;
        return (
            <Input type="date" onChange={this.onDatePickerChange} value={this.state.value} {...newProps} />
        )
    }
}

export default DatePicker;