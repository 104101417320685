export default function SectionTitle({ children, actions }) {
    return (<div className="mt-3 sm:mt-2">
        <div className="block w-full">
            {actions ? <div className="flex justify-between items-center border-b border-gray-200 w-full pl-10 pr-5 py-2 pb-5">
                <h1 className="text-2xl text-primary font-medium">
                    {children}
                </h1>
                {actions ? <div className="self-center flex space-x-2">{actions.map(a => a)}</div> : null}
            </div>
                : <h1 className="text-2xl text-primary font-medium">{children}</h1>
            }
        </div>
    </div>)
}