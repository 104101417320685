import classNames from "classnames";
import shortid from "shortid";
import Card from "../Card/Card";
import FakeText from "./FakeText";

function FakeCard({ key, length }) {
    return typeof (key) === "number" && length ? <div
        key={shortid.generate()}
        className={classNames(
            key === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
            key === 1 ? 'sm:rounded-tr-lg' : '',
            key === length - 1 ? (length % 2 === 0 ?
                'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : 'rounded-bl-lg sm:rounded-bl-none') : '',
            'relative group bg-white focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500 cursor-pointer shadow shadow-md+'
        )}
    >
        <div className="p-4">
            <h3 className="text-lg font-medium">
                <div className="focus:outline-none">
                    <FakeText />
                </div>
            </h3>
            <div className="mt-8 text-sm text-gray-500">
                <div className="grid grid-cols-5 gap-4">
                    <div className="col-span-2"><FakeText /></div>
                    <div className="col-span-1"><FakeText /></div>
                </div>
                <div className="grid grid-cols-6 gap-4 mt-2">
                    <div className="col-span-1"><FakeText /></div>
                </div>
                <div className="grid grid-cols-3 gap-4 mt-2">
                    <div className="col-span-1"><FakeText /></div>
                </div>
            </div>
        </div>
    </div> : <Card>
        <div className="p-4">
            <h3 className="text-lg font-medium">
                <div className="focus:outline-none">
                    <FakeText />
                </div>
            </h3>
            <div className="mt-8 text-sm text-gray-500">
                <div className="grid grid-cols-5 gap-4">
                    <div className="col-span-2"><FakeText /></div>
                    <div className="col-span-1"><FakeText /></div>
                </div>
                <div className="grid grid-cols-6 gap-4 mt-2">
                    <div className="col-span-1"><FakeText /></div>
                </div>
                <div className="grid grid-cols-3 gap-4 mt-2">
                    <div className="col-span-1"><FakeText /></div>
                </div>
            </div>
        </div></Card>
}

export default FakeCard;