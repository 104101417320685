import React from "react";
import handleCondition from "./handleConditionOnQuestion";
import * as FormsComponent from "../../../components/Inputs/forms/index";
import classNames from "classnames";
import Stripe from "../../../components/Forms/Stripe";
// import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import { InformationCircleIcon } from "@heroicons/react/outline";
import Recap from "../../../components/Recap";
import ReactPlayer from 'react-player'
import Paybox from "../../../components/Forms/Paybox";
import { Description, Field, Label, Legend } from '../../../components/catalyst-component/fieldset'

class Question extends React.Component {
  constructor(props) {
    super(props);
    this.callbackSubForm = callbackSubForm.bind(this);
  }
  render() {
    return !this.props.hidden && (
      <Field>
        <Label className="mb-2 text-xs">{this.props.title} {this.props.required ? <span className="text-red-500">*</span> : ""} {this.props.tooltip ? <>
          <div data-tooltip-id={"tooltip_" + this.props.id} data-tooltip-content={this.props.tooltip}>
            <InformationCircleIcon className="h-4" />
          </div>
          <Tooltip id={"tooltip_" + this.props.id}>{this.props.tooltip}</Tooltip>
        </> : null}
        </Label>
        {this.props.children ?
          (this.props.children.length ? this.props.children.map((c, k) => {
            return c ? <div key={k}>{c}</div> : <></>;
          }) : <></>) :
          <></>}
        {this.props.description && <Description>{this.props.description}</Description>}
      </Field>
    );
  }
}

function handleComponentSelection(question, errorQ, onDataChange) {
  const { t } = this.props;
  switch (question.fieldType) {
    case "text":
      return (
        <FormsComponent.Input
          id={question.name}
          type="text"
          readOnly={question.readOnly}
          placeholder={t("Response")}
          value={
            this.state.formData && this.state.formData[question.name]
              ? this.state.formData[question.name]
              : ""
          }
          onChange={(value) => {
            var formData = this.state.formData;
            formData[question.name] = value;
            this.setState({ formData });
            onDataChange(formData, question)
          }}
          error={errorQ}
        />
      );
    case "number":
    case "NumberPicker":
      return (
        <FormsComponent.Input
          id={question.name}
          readOnly={question.readOnly}
          type="number"
          value={
            this.state.formData && this.state.formData[question.name] !== null
              ? this.state.formData[question.name]
              : 0
          }
          onChange={(value) => {
            var formData = this.state.formData;
            formData[question.name] = value;
            if(value.startsWith(0)){
              formData[question.name] = value.split('0')[1];
            }
            if(!value){
              formData[question.name] = 0
            }
            this.setState({ formData });
            onDataChange(formData, question)
          }}
          error={errorQ}
        />
      );
    case "file":
      return (
        <FormsComponent.FileUpload
          id={question.name}
          readOnly={question.readOnly}
          formId={this.props.formId} dealId={this.props.dealId} contactId={this.props.contactId} questionId={question.id}
          placeholder={t("Response")}
          onChange={(value) => {
            var formData = this.state.formData;
            formData[question.name] = value;
            this.setState({ formData });
            onDataChange(formData, question)
          }}
          value={
            this.state.formData && this.state.formData[question.name]
              ? this.state.formData[question.name]
              : null
          }
          msalInstance={this.props.msalInstance}
          error={errorQ}
        />
      );
    case "date":
      return (
        <FormsComponent.DatePicker
          id={question.name}
          readOnly={question.readOnly}
          placeholder={t("Response")}
          value={
            this.state.formData && this.state.formData[question.name]
              ? this.state.formData[question.name]
              : ""
          }
          onChange={(value) => {
            var formData = this.state.formData;
            formData[question.name] = value;
            this.setState({ formData });
            onDataChange(formData, question)
          }}
          error={errorQ}
        />
      );
    case "DateTimePicker":
      return (
        <FormsComponent.DateTimePicker
          id={question.name}
          readOnly={question.readOnly}
          placeholder={t("Response")}
          value={
            this.state.formData && this.state.formData[question.name]
              ? this.state.formData[question.name]
              : ""
          }
          onChange={(value) => {
            var formData = this.state.formData;
            formData[question.name] = value;
            this.setState({ formData });
            onDataChange(formData, question)
          }}
          error={errorQ}
        />
      );
    case "timePicker":
      return (
        <FormsComponent.TimePicker
          id={question.name}
          readOnly={question.readOnly}
          placeholder={t("Response")}
          value={
            this.state.formData && this.state.formData[question.name]
              ? this.state.formData[question.name]
              : ""
          }
          onChange={(value) => {
            var formData = this.state.formData;
            formData[question.name] = value;
            this.setState({ formData });
            onDataChange(formData, question)
          }}
          error={errorQ}
        />
      );
    case "booleancheckbox":
      return <div role="group" id={question.id}>
        {question.readOnly ? <FormsComponent.Input
          id={question.name}
          type="text"
          readOnly={true}
          placeholder={t("Response")}
          value={question.options.find(opt => opt.value === question.value).label}
        /> : question.options.map(opt => <div key={opt.value} className="mt-2 ml-2 space-y-2">
          <div className="flex items-center">
            <input
              id={opt.value}
              name={question.id}
              type="radio"
              checked={this.state.formData && this.state.formData[question.name] ? this.state.formData[question.name] === opt.value : false}
              onClick={() => {
                var formData = this.state.formData;
                formData[question.name] = opt.value;
                this.setState({ formData });
                onDataChange(formData, question)
              }}
              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
            <label htmlFor={opt.value} className="ml-3 block text-sm font-medium text-gray-700">
              {opt.label}
            </label>
          </div>
        </div>)}
      </div>
    case "textarea":
      return (
        <FormsComponent.TextArea
          id={question.name}
          readOnly={question.readOnly}
          placeholder={t("Response")}
          rows="4"
          value={
            this.state.formData && this.state.formData[question.name]
              ? this.state.formData[question.name]
              : ""
          }
          onChange={(value) => {
            var formData = this.state.formData;
            formData[question.name] = value;
            this.setState({ formData });
            onDataChange(formData, question)
          }}
          error={errorQ}
        />
      );
    case "switch":
      return (
        <FormsComponent.Switch
          value={
            this.state.formData && this.state.formData[question.name]
              ? this.state.formData[question.name]
              : false
          }
          readOnly={question.readOnly}
          onChange={(value) => {
            var formData = this.state.formData;
            formData[question.name] = value;
            this.setState({ formData });
            onDataChange(formData, question)
          }}
        />
      );
    case "slider":
      return (
        <FormsComponent.Slider
          id={question.name}
          readOnly={question.readOnly}
          min={question.min}
          max={question.max}
          value={
            this.state.formData &&
              (this.state.formData[question.name] ||
                this.state.formData[question.name] === 0)
              ? this.state.formData[question.name]
              : Math.trunc((question.min + question.max) / 2)
          }
          onChange={(newValue) => {
            var formData = this.state.formData;
            formData[question.name] = newValue;
            this.setState({ formData });
            onDataChange(formData, question)
          }}
          {...question.componentParameter}
        />
      );
    case "select":
      return (
        <div key={question.name}>
          {question.readOnly ? <FormsComponent.Input
            id={question.name}
            type="text"
            readOnly={true}
            placeholder={t("Response")}
            value={question?.options.find(opt => opt.value === question.value)?.label}
          /> : <select className="border block w-full sm:text-xs border-gray-300 rounded-md h-8 px-3 text-xs"
            onChange={(e) => {
              var formData = this.state.formData;
              formData[question.name] = e.target.value;
              this.setState({ formData });
              onDataChange(formData, question)
            }}
            value={this.state.formData[question.name]}>
            <option value=""></option>
            {question.options.filter(opt => question.optionsCustom ? question.optionsCustomList.find(o => o === opt.value) : true).map(opt => <option key={opt.value} value={opt.value}>{opt.label}</option>)}
          </select>}
        </div>

      );
    case "selectMultiple":
      return (
        <FormsComponent.SelectMultiple
          key={question.name}
          readOnly={question.readOnly}
          id={question.name}
          value={
            this.state.formData && this.state.formData[question.name]
              ? this.state.formData[question.name]
              : null
          }
          keys={
            this.state.formData && this.state.formData[question.name]
              ? this.state.formData[question.name]
              : null
          }
          onChange={(value) => {
            var formData = this.state.formData;
            formData[question.name] = value;
            this.setState({ formData });
            onDataChange(formData, question)
          }}
          options={
            question.listChoices
              ? question.listChoices.map((choice) => {
                return { key: choice.id, id: choice.name };
              })
              : []
          }
        />
      );
    case "checkbox":
      return (
        <FormsComponent.SelectMultiple
          key={question.name}
          readOnly={question.readOnly}
          id={question.name}
          value={
            this.state.formData && this.state.formData[question.name]
              ? this.state.formData[question.name]
              : null
          }
          keys={
            this.state.formData && this.state.formData[question.name]
              ? this.state.formData[question.name]
              : null
          }
          onChange={(value) => {
            var formData = this.state.formData;
            formData[question.name] = value;
            this.setState({ formData });
            onDataChange(formData, question)
          }}
          options={
            question.listChoices
              ? question.listChoices.map((choice) => {
                return { key: choice.id, id: choice.name };
              })
              : []
          }
        />
      );
    case "selected":
      return (
        <div>
          <div>
            {this.state.formData && this.state.formData[question.name]
              ? typeof this.state.formData[question.name] !== "object"
                ? this.state.formData[question.name + "name"]
                : this.state.formData[question.name + "name"]
              : null}
          </div>
        </div>
      );
    default:
      return process.env.NODE_ENV === "development" ?<>NOT IMPLEMENTED :{question.component} , {question.fieldType}</> : null;
  }
}

function getQuestionRender(question, key, onDataChange, formId, dealId, msalInstance, currentStep, setStep) {
  if (handleCondition(this.state.formData, question, this.state.conditionInTheForm)) {
    let handleComponent = handleComponentSelection.bind(this);
    let getQRender = getQuestionRender.bind(this);

    var errorQ = this.state.errors &&
      this.state.errors.inner &&
      this.state.errors.inner.find((o) => o.path === question.name)
      ? this.state.errors.inner.find((o) => o.path === question.name).message
      : null;
    if (question.fieldType === "custom input") {
      switch (question.objectId) {
        case "Image":
          return <img key={key}
            className={classNames(key === 0 ? "" : "mt-4", "right-0")}
            style={{maxWidth: "33vw"}}
            src={question.value}
            alt={question.value}
          />
        case "Video":
          return <div className="mx-auto w-full">
            {/* <iframe  src={question.value}/> */}
            <ReactPlayer url={question.value} width="xs:90vw md:33vw"/>
          </div>
        case "Title":
        case "Titre":
        case "Texte":
          return <Legend key={key} className="mt-8 pt-6 border-t">{question.value}</Legend>
        case "Description":
          return <div key={key} className="mt-1 text-xs text-gray-500 block" style={{ whiteSpace: "pre-line" }}>{question.value}</div>
        case "Stripe":
          return <Stripe key={key} question={question} dealId={dealId} formId={formId} />
        case "Paybox":
          return <Paybox key={key} question={question} dealId={dealId} formId={formId} />
        case "Recap":
          return <Recap key={key} currentStep={currentStep} setStep={setStep} />
        case "Redirect":
          return <div key={key} className="cursor-pointer font-semibold" onClick={()=>{window.open(question.value,"_blank")}}>{question.label}</div>
        case "Logic block":
        case "Logic":
          return null
        default:
          return process.env.NODE_ENV === "development" ? <>Not implemented : {question.objectId}</> : null
      }
    } else {
      return (
        <Question
          key={key}
          {...question}
          error={errorQ}
          title={question.label}
          msalInstance={msalInstance}
        >
          <div key={key + "handleComponent"} className="mb-5">{handleComponent(question, errorQ, onDataChange)}</div>
          <div key={key + "subQuestions"}>{question.subQuestions
            ? question.subQuestions.map((sb, sbKey) => {
              return getQRender(sb, sbKey);
            })
            : ""}</div>
        </Question>
      );
    }
  } else {
    return "";
  }
}

function callbackSubForm(formData, question) {

}

function verifyDependance(question) {
  return question.sub;
}

function initResponse(question) {
  if (question.value) {
    switch (question.widget) {
      case "datePicker":
        return new Date(question.value);
      default:
        return question.value;
    }

  }
  switch (question.widget) {
    case "Slider":
      return Math.trunc((question.min + question.max) / 2);
    case "Switch":
      return false;
    case "datePicker":
      return new Date();
    default:
      return null;
  }
}

export {
  initResponse,
  handleComponentSelection,
  verifyDependance,
  getQuestionRender
};
