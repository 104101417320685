import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Step from "../components/Text/Steps";
import { createClassForHSForm } from "../utils/requests/form";
import "../Hubspot.css"
import Form from "../utils/forms/handleForm";
import { postCandidature } from "../utils/requests/candidatures";
import { CandidaturesContext, ThemeContext, ContactIdContext } from "../App"
import { FormTitleContext } from "../layout/Vertical/Global";
import { MsalInstanceContext, VariablesContext } from "../auth/AppProvider";
import { SetTrakingHubspot } from "../utils/functions/trackingHubspot";
import HandleErrorsMessage from "./Candidature/HandleErrorsMessage";
import HandleFinalMessage from "./Candidature/HandleFinalMessage";
import { withTranslation } from "react-i18next";
import { getStripeStatus } from "../utils/requests/stripe";
import FakeList from "../components/Fake/FakeList";
import FakeCard from "../components/Fake/FakeCard";


function Candidature({ t }) {
    const [candidature, setCandidature] = useState({});
    const [, setFormTitle] = useContext(FormTitleContext);
    const [step, setStep] = useState(0);
    const [form, setForm] = useState({});
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [pageLoading, setPageLoading] = useState(false);

    let navigate = useNavigate();
    let { id, checkoutid } = useParams();

    const [candidatures, setCandidatures] = useContext(CandidaturesContext);
    const contactId = useContext(ContactIdContext);
    const theme = useContext(ThemeContext);
    const msalInstance = useContext(MsalInstanceContext);
    const variables = useContext(VariablesContext);


    useEffect(() => {
        createClassForHSForm(theme);
        var candidatureCurr = candidatures && candidatures.find(c => c.id === id);
        var formCurr = {};
        if (candidatureCurr && candidatureCurr.step && candidatureCurr.step[0]) {
            if (checkoutid && checkoutid !== "cancel") {
                setPageLoading(true);
                if (candidatureCurr.step.find(st => st.questions.find(q => q.label === "Paybox"))) {
                    var index = candidatureCurr.step.findIndex(st => st.questions.find(q => q.label === "Paybox" && q.fieldType === "custom input"))
                    if (index >= 0 && candidatureCurr.step[index]) {
                        setStep(index);
                        formCurr = candidatureCurr.step[index];
                    } else {
                        formCurr = candidatureCurr.step[0];
                    }
                    setCandidature(candidatureCurr);
                    setForm(formCurr);
                    setPageLoading(false);
                } else if (candidatureCurr.step.find(st => st.questions.find(q => q.label === "Stripe"))) {
                    getStripeStatus(msalInstance, variables, candidatureCurr.dealId, checkoutid).then(res => {
                        if (res) {
                            var index = candidatureCurr.step.findIndex(st => st.questions.find(q => q.label === "Stripe" && q.fieldType === "custom input"))
                            if (index >= 0 && candidatureCurr.step[index]) {
                                setStep(index);
                                formCurr = candidatureCurr.step[index];
                            } else {
                                formCurr = candidatureCurr.step[0];
                            }
                            setCandidature(candidatureCurr);
                            setForm(formCurr);
                            setPageLoading(false);
                        } else {
                            navigate("/");
                        }
                    })
                }

            }
            else {
                formCurr = candidatureCurr.step[0];
                setCandidature(candidatureCurr);
                setForm(formCurr);
            }
        } else {
            navigate("/")
        }
    }, [id, candidatures, theme, navigate, t, checkoutid, msalInstance, variables]);

    useEffect(() => {
        if (candidature && candidature.parameters) {
            setFormTitle(candidature?.parameters?.find(p => p.name === "name")?.value)
            SetTrakingHubspot(msalInstance, variables.HSTenantID, variables.ClientName, candidature?.parameters?.find(p => p.name === "name")?.value + " - Etape : " + form.name);
        }
    }, [variables, candidature, msalInstance, step, form])

    function handleChangeStep(locstep) {
        setLoading(true);
        if (locstep < 0) {
            locstep = 0
        }
        setStep(() => {
            setForm(candidature.step[locstep])
            setLoading(false);
            window.scrollTo(0, 0);
            return locstep;
        });
    }


    function handleSubmit(formData) {
        setErrors(false);
        setLoading(true);
        var data = {};
        data.id = candidature.id;
        data.step = step;
        data.dealId = candidature.dealId;
        data.contactId = contactId;
        var cand = candidature;
        cand.step.forEach(step => {
            step.questions.forEach(question => {
                if (formData[question.name]) {
                    question.value = formData[question.name]
                }
            })
        })
        data.formData = cand;
        return postCandidature(msalInstance, variables, data).then(res => {
            if (res) {
                var index = candidatures.findIndex(c => c.id === res.id);
                var candidaturesLocal = candidatures;
                candidaturesLocal[index] = res;
                setCandidatures(candidaturesLocal)
                setCandidature(res);
                setStep(s => {
                    setForm(res.step[s + 1])
                    setLoading(false);
                    window.scrollTo(0, 0);
                    return s + 1;
                });
            } else {
                window.scrollTo(0, 0);
                setErrors(true);
                setLoading(false);
            }
        }).catch(err => {
            window.scrollTo(0, 0);
            setErrors(err);
            setLoading(false);
        })
    }

    return pageLoading ? <div className="mt-4">
        <FakeList component={FakeCard} length={1} />
    </div> :
        <div className="bg-white h-full relative">
            <div className=" py-5 bg-zinc-900 sticky top-0 z-10">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mx-auto h-full ">{candidature && candidature.step ? (candidature.step.length > 1 ?
                    <Step steps={candidature.step} currStep={step} /> : <h3 className="px-4 text-lg leading-6 font-medium text-white">{candidature.step[0].name}</h3>
                ) : null}
                </div>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mx-auto">
                <div className="sm:grid sm:grid-cols-2 relative">
                    <div>
                        {candidature && candidature.parameters ? <div className="pt-5">
                            <h1 style={{ fontSize: "1.5rem", lineHeight: "2rem", fontWeight: 600, marginBottom: ".5rem" }}>{candidature.step[step]?.name}</h1>
                        </div> : null}
                        {candidature && candidature.step && step >= candidature.step.length ? <HandleFinalMessage /> : <div>

                            {errors ? <HandleErrorsMessage errors={errors} /> : <></>}
                            <div>
                                {form && form.questions ? <Form form={form.questions} handleSubmit={handleSubmit} theme={theme} contactId={contactId} dealId={candidature.dealId} formId={candidature.id} loading={loading ? true : false} msalInstance={msalInstance} currentStep={step} setStep={handleChangeStep} /> : null}
                            </div>
                        </div>}
                    </div>
                    <img className="hidden sm:block sticky top-0 pl-6 right-0" style={{ maxWidth: "50vw" }} alt="student" src={theme?.page_logo ? "data" + theme.page_logo.split(";data")[1] : "https://static.vecteezy.com/system/resources/previews/003/591/902/large_2x/one-line-drawing-of-young-happy-boy-student-wearing-graduation-hat-and-giving-thumbs-up-gesture-while-holding-graduation-paper-roll-education-concept-continuous-line-draw-design-illustration-vector.jpg"} />
                </div>

            </div>
        </div>
}

export default withTranslation()(Candidature);