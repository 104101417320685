import { Fragment, useEffect, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { withTranslation } from 'react-i18next'

function getFilteredData(filters, listCandidatures) {
    var cands = listCandidatures;
    filters.forEach(filter => {
        const values = filter.options.filter(opt => opt.actif)
        if (values && values.length) {
            cands = cands.filter(c => values.find(v => v.value === c.parameters?.find(pa => pa.name === filter.id && pa.filter)?.value));
        }
    });
    return cands
}

function FilterBarCandidature({ t, candidatures, setFilteredCandidatures }) {
    const [filters, setFilters] = useState(null);

    useEffect(() => {
        if (candidatures && candidatures.length) {
            var filtersContruction
            if (localStorage.getItem("filterparameter")) {
                // filtersContruction = JSON.parse(localStorage.getItem("filterparameter"))
                var filtersContructionLS = JSON.parse(localStorage.getItem("filterparameter"))
                filtersContruction = candidatures[0]?.parameters?.map(p => { return { id: p.name, name: p.label } });
                filtersContruction = filtersContruction.map(p => {
                    p.options = candidatures.map(c => c.parameters?.find(pa => pa.name === p.id && pa.filter)?.value).filter((value, index, self) => self.indexOf(value) === index).filter(c => c !== "" && c !== null && c !== undefined).map(c => {
                        var section = filtersContructionLS?.find(section => section.id === p.id)
                        var option = section?.options?.find(o => o.value === c)
                        return { value: c, label: c, actif: option?.actif ? true : false }
                    })
                    return p
                }).filter(p => p?.options?.length > 1)
            } else {
                filtersContruction = candidatures[0]?.parameters?.map(p => { return { id: p.name, name: p.label } });
                filtersContruction = filtersContruction.map(p => {
                    p.options = candidatures.map(c => c.parameters?.find(pa => pa.name === p.id && pa.filter)?.value).filter((value, index, self) => self.indexOf(value) === index).filter(c => c !== "" && c !== null && c !== undefined).map(c => { return { value: c, label: c, actif: false } })
                    return p
                }).filter(p => p?.options?.length > 1)
            }
            setFilters(filtersContruction);
            setFilteredCandidatures(getFilteredData(filtersContruction, candidatures));
        }
    }, [candidatures, t, setFilteredCandidatures]);

    function handleOnChange(filter) {
        setFilters(filter);
        localStorage.setItem("filterparameter", JSON.stringify(filter))
        setFilteredCandidatures(getFilteredData(filter, candidatures));
    }


    return filters && filters.length ? <div className="flex items-center justify-between px-2">
        <Popover.Group className="flex items-baseline space-x-2 sm:space-x-8">
            {filters.filter(f => f.id !== "name" && f.id !== "description").map((section, sectionIdx) => (
                <Popover
                    as="div"
                    key={section.id}
                    id={`desktop-menu-${sectionIdx}`}
                    className="relative z-10 inline-block text-left"
                >
                    <div>
                        <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                            <span>{section.name}</span>
                            {section.options && section.options.filter(opt => opt.actif).length > 0 ? (
                                <span className="ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums">
                                    {section.options.filter(opt => opt.actif).length}
                                </span>
                            ) : null}
                            <ChevronDownIcon
                                className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                            />
                        </Popover.Button>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Popover.Panel className="origin-top-right absolute left-0 mt-2 bg-white rounded-md shadow-2xl p-4" key={`panel-${sectionIdx}`}>
                            <form className="space-y-4" key={`form-${sectionIdx}`}>
                                {section.options.map((option, optionIdx) => (
                                    <div key={option.value} className="flex items-center cursor-pointer">
                                        <input
                                            onChange={() => {
                                                var filtersLocal = filters;
                                                var filterLocal = filters[sectionIdx];
                                                var optionLocal = filterLocal.options[optionIdx];
                                                optionLocal.actif = !optionLocal.actif;
                                                filterLocal.options[optionIdx] = optionLocal;
                                                filtersLocal[sectionIdx] = filterLocal;
                                                handleOnChange(filtersLocal)
                                            }}
                                            id={`filter-${section.id}-${optionIdx}`}
                                            name={`${section.id}[]`}
                                            checked={option.actif}
                                            type="checkbox"
                                            className="h-4 w-4 border-gray-300 rounded text-indigo-600 cursor-pointer"
                                        />
                                        <label
                                            htmlFor={`filter-${section.id}-${optionIdx}`}
                                            className="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap cursor-pointer"
                                        >
                                            {option.label}
                                        </label>
                                    </div>
                                ))}
                            </form>
                        </Popover.Panel>
                    </Transition>
                </Popover>
            ))}
        </Popover.Group>
    </div> : null
}

export default withTranslation()(FilterBarCandidature);