import React, { useContext } from "react";
import { verifyAuth } from "../../auth/verifyAuth";
import routes from "./routes";
import { Outlet, useRoutes } from "react-router";
import NotFound from "../../pages/NotFound";
import { MsalInstanceContext } from "../../auth/AppProvider";

const Global = React.lazy(() =>
  import("../../layout/" + process.env.REACT_APP_ORIENTATION + "/Global")
);

function HandleAuthent({msalInstance}) {
  return verifyAuth(msalInstance, <Outlet />);
}

function Router() {
  const msalInstance = useContext(MsalInstanceContext);
  let el = useRoutes([
    {
      element: <HandleAuthent msalInstance={msalInstance}/>,
      children: [
        {
          element: <Global/>,
          children: routes
        }
      ]
    },
    {
      path: "*",
      element: <NotFound />
    }
  ]);
  return el;
}

export default Router;
