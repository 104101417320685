import React, { Suspense, useContext, useEffect, useState } from "react";

// language
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { LoadingComponent } from "./auth/components";
import i18n from "./locale/i18n";

// Router
import Router from "./utils/router/router";

// Cookies acception management
import { getAllCandidatures } from "./utils/requests/candidatures";
import { getPipelines } from "./utils/requests/pipelines";
import { VariablesContext } from "./auth/AppProvider";

export const ThemeContext = React.createContext();
export const CandidaturesContext = React.createContext();
export const ContactIdContext = React.createContext();
export const HSPipelineContext = React.createContext();

function App({ msalInstance }) {
  const variables = useContext(VariablesContext)

  const [theme, setTheme] = useState(null)
  const [candidatures, setCandidatures] = useState(null);
  const [loadingCandidature, setLoadingCandidature] = useState(true);
  const [contactId, setContactId] = useState(null);
  const [hspipelines, setHSPipelines] = useState(null);


  useEffect(() => {
    getAllCandidatures(msalInstance, variables).then(cand => {
      if (cand) {
        setCandidatures(cand.candidatures);
        setContactId(cand.contactId);
        setLoadingCandidature(false);
      }
    });

    fetch("/parameters/theme.json").then(res => {
      if (res.ok) {
        return res.json()
      }
    }
    ).then(them => {
      setTheme(them)
      var link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.head.appendChild(link);
      }
      link.href = "data" + them.favicon.split(";data")[1]
    });

    getPipelines(msalInstance, variables).then(pipelines => { setHSPipelines(pipelines.results) });
  }, [msalInstance, variables])

  return !loadingCandidature && theme && candidatures ? <I18nextProvider i18n={i18n}>
    <BrowserRouter>
      <Suspense fallback={<LoadingComponent />}>
        <ThemeContext.Provider value={theme}>
          <CandidaturesContext.Provider value={[candidatures, setCandidatures]}>
            <ContactIdContext.Provider value={contactId}>
              <HSPipelineContext.Provider value={hspipelines}>
                <Router />
              </HSPipelineContext.Provider>
            </ContactIdContext.Provider>
          </CandidaturesContext.Provider>
        </ThemeContext.Provider>
      </Suspense>
    </BrowserRouter>
  </I18nextProvider> : null
}

export default App;



