export default function handleCondition(formData, question, conditionInForm) {
    if (conditionInForm && conditionInForm.length > 0) {
        if (conditionInForm.find(c => c.question === question.id)) {
            var list = conditionInForm.filter(c => c.question === question.id)
            for (var cind in list) {
                var c = list[cind]
                return isConditionTrue(c.condition, formData, c.condition.cond, c.parent, conditionInForm);
            }
        } else {
            return true
        }
    } else {
        return true;
    }
}

function isConditionTrue(condition, formData, cond, parent, conditionList) {
    try {
        if (condition.select) {
            switch (condition.select) {
                case "contains any of":
                    return cond.find(p => p === formData[parent]) ? true : false;
                case "contains none of":
                    return cond.find(p => p === formData[parent]) ? false : true;
                case "is not empty":
                    return formData[parent] || formData[parent] === 0 ? true : false;
                case "is equal to":
                    return cond[0] === formData[parent] ? true : false;
                case "is not equal to":
                    return cond[0] === formData[parent] ? false : true;
                case "contains all of":
                    return cond.find(p => !formData[parent].find(c => c === p)) ? false : true;
                case "doesn't contain all of":
                    return formData[parent].find(p => cond.find(c => c === p)) ? false : true;
                case "is less than":
                    return Number(formData[parent]) < Number(cond[0]) ? true : false;
                case "is less than or equal to":
                    return formData[parent] <= cond[0] ? true : false;
                case "is greater than":
                    return formData[parent] > cond[0] ? true : false;
                case "is greater than or equal to":
                    return formData[parent] >= cond[0] ? true : false;
                case "is between":
                    return formData[parent] >= cond[0] && formData[parent] <= cond[1] ? true : false;
                case "is not between":
                    return formData[parent] < cond[0] || formData[parent] > cond[1] ? true : false;
                case "else":
                    var locList = conditionList.filter(c => c.parent === parent && c.condition.select !== "else")
                    for (var x in locList) {
                        if (isConditionTrue(locList[x]?.condition, formData, locList[x]?.condition?.cond, parent, conditionList)) {
                            return false;
                        }
                    }
                    return true;
                default:
                    return false;
            }
        } else if (condition.multi) {
            var values = formData[parent].split(";");
            switch (condition.multi) {
                case "is equal to":
                    return cond === values ? true : false;
                case "is not equal to":
                    return cond === values ? false : true;
                case "contains any of":
                    return values.find(p => cond.find(c => c === p)) ? true : false;
                case "contains none of":
                    return values.find(p => cond.find(c => c === p)) ? false : true;
                case "contains all of":
                    return cond.find(p => !values.find(c => c === p)) ? false : true;
                case "doesn't contain all of":
                    return values.find(p => cond.find(c => c === p)) ? false : true;
                case "is not empty":
                    return values ? true : false;
                default:
                    return false;
            }
        } else if (condition.date) {
            var value = formData[parent] ? new Date(formData[parent]) : null;
            var date1 = cond && cond.length > 0 ? new Date(cond[0]) : null;
            var date2 = cond && cond.length > 1 ? new Date(cond[1]) : null;
            switch (condition.date) {
                case "is equal to":
                    return date1.getTime() === value.getTime() ? true : false;
                case "is less than":
                    return date1.getTime() > value.getTime() ? true : false;
                case "is greater than":
                    return date1.getTime() < value.getTime() ? true : false;
                case "is between":
                    return date1.getTime() <= value.getTime() && date2.getTime() >= value.getTime() ? true : false;
                case "is not between":
                    return date1.getTime() > value.getTime() || date2.getTime() < value.getTime() ? true : false;
                case "was more than":
                    return date1.getTime() < value.getTime() ? true : false;
                case "was less than":
                    return date1.getTime() > value.getTime() ? true : false;
                case "is not empty":
                    return value ? true : false;
                default:
                    return false;
            }
        } else if (condition.booleancheckbox) {
            switch (condition.booleancheckbox) {
                case "is equal to":
                    return cond.find(p => p === formData[parent]) ? true : false;
                case "is not empty":
                    return typeof (formData[parent]) === "number" || typeof (formData[parent]) === "boolean" || formData[parent] ? true : false;
                default:
                    return false;
            }
        } else if (condition.number) {
            switch (condition.number) {
                case "is equal to":
                    return cond[0] === formData[parent] ? true : false;
                case "is not equal to":
                    return cond[0] === formData[parent] ? false : true;
                case "is less than":
                    return formData[parent] < cond[0] ? true : false;
                case "is less than or equal to":
                    return formData[parent] <= cond[0] ? true : false;
                case "is greater than":
                    return formData[parent] > cond[0] ? true : false;
                case "is greater than or equal to":
                    return formData[parent] >= cond[0] ? true : false;
                case "is between":
                    return formData[parent] >= cond[0] && formData[parent] <= cond[1] ? true : false;
                case "is not between":
                    return formData[parent] < cond[0] || formData[parent] > cond[1] ? true : false;
                case "is not empty":
                    return formData[parent] ? true : false;
                default:
                    return false;
            }
        } else if (condition.file) {
            switch (condition.file) {
                case "is not empty":
                    return formData[parent] ? true : false;
                default:
                    return false;
            }
        } else if (condition.misc) {
            switch (condition.misc) {
                case "is equal to":
                    return cond[0] === formData[parent] ? true : false;
                case "is not equal to":
                    return cond[0] === formData[parent] ? false : true;
                case "contains":
                    return formData[parent].includes(cond[0]) ? true : false;
                case "doesn't contain":
                    return formData[parent].includes(cond[0]) ? false : true;
                case "start with":
                    return formData[parent].startsWith(cond[0]) ? true : false;
                case "ends with":
                    return formData[parent].endsWith(cond[0]) ? true : false;
                case "is not empty":
                    return formData[parent] ? true : false;
                default:
                    return false;
            }
        }
    } catch {
        return false;
    }

}

// function isValueVerified(type, question, value) {
//     if (question) {
//         if (type !== "array" && typeof (question) !== "object") {
//             return question === value;
//         } else {
//             return question.includes(value);
//         }
//     } else {
//         return false;
//     }
// }
