// import all pages 
import Home from "../../pages/Home";
import Candidature from "../../pages/Candidature";

//import icons
import { AcademicCapIcon, HomeIcon } from '@heroicons/react/outline'


const routes = [
    {
        path: "/",
        module: "home",
        element: <Home />,
        exact: true,
        isMenuIcon: false,
        logo: HomeIcon,
        name: "Home"
    },
    {
        path: "/candidatures/:id",
        module: "candidatures",
        element: <Candidature />,
        exact: true,
        isMenuIcon: false,
        logo: AcademicCapIcon,
        name: "Candidatures"
    },
    {
        path: "/candidatures/:id/:checkoutid",
        module: "candidatures",
        element: <Candidature />,
        exact: true,
        isMenuIcon: false,
        logo: AcademicCapIcon,
        name: "Candidatures"
    }
]


export default routes;