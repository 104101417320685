import React, { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import CandidatureCard from "../../components/Card/CandidatureCard";
import FakeCard from "../../components/Fake/FakeCard";
import FakeList from "../../components/Fake/FakeList";
import { CandidaturesContext, HSPipelineContext } from "../../App"
import { FormTitleContext } from "../../layout/Vertical/Global";
import { MsalInstanceContext, VariablesContext } from "../../auth/AppProvider";
import { SetTrakingHubspot } from "../../utils/functions/trackingHubspot";

import { useNavigate } from "react-router";
import { Badge } from "../../components/catalyst-component/badge";
import { Button } from "../../components/catalyst-component/button";
import FilterBarCandidature from "./FilterBarCandidature";
import InitFormDialog from "../../components/Overlays/InitFormDialog";

function AllCandidatures({ t }) {
    const [allcandidatures,] = useContext(CandidaturesContext);
    const [, setFormTitle] = useContext(FormTitleContext);
    const hspipelines = useContext(HSPipelineContext);
    const variables = useContext(VariablesContext)
    // init state
    const [dataFiltered, setFilteredData] = useState([]);
    const [loadingCandidature, setLoadingCandidature] = useState(true);
    const [candidatures, setCandidatures] = useState([]);
    const [myCandidatures, setMyCandidatures] = useState([]);
    const [preSetupNotDone, setPreSetupNotDone] = useState(false);

    const msalInstance = useContext(MsalInstanceContext);
    let navigate = useNavigate();


    useEffect(() => {
        setFormTitle(null);
        SetTrakingHubspot(msalInstance, variables.HSTenantID, variables.ClientName, "Home");
    }, [variables, msalInstance])

    useEffect(() => {
        if (!localStorage.getItem("filterparameter")) {
            setPreSetupNotDone(true)
        }
        if (allcandidatures && allcandidatures.length) {
            setMyCandidatures(allcandidatures.filter(c => c.dealId || c.finished));
            setCandidatures(allcandidatures.filter(c => !c.dealId && !c.finished));
            setFilteredData(allcandidatures.filter(c => !c.dealId && !c.finished));
            setLoadingCandidature(false);
        }
    }, [allcandidatures, msalInstance])

    return candidatures ? <div className="bg-gray-100 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mx-auto h-full">
        {preSetupNotDone && <InitFormDialog returnInitForm={() => { var locCandidatures = candidatures; Promise.all([setCandidatures([])]).then(() => { setCandidatures(locCandidatures); }) }} />}
        {!loadingCandidature && myCandidatures && myCandidatures.length > 0 ? <section aria-labelledby="quick-links-title">
            <div className="flex items-center px-2 md:px-0 mb-2  mt-4">
                <div className="flex-auto pt-4">
                    <h1 style={{ fontSize: "1.5rem", lineHeight: "2rem", fontWeight: 600, marginBottom: ".5rem" }}>{t("My Candidatures")}</h1>
                </div>
            </div>
            <div className="mt-1">
                <h2 className="sr-only" id="quick-links-title" >
                    {t("My Candidatures")}
                </h2>
                <div className="grid grid-cols-1 gap-6">
                    {myCandidatures.map((candidature, candidatureId) => {
                        var cantBeUpdated = false;
                        var stage = (hspipelines.find(pip => pip.id === candidature.pipeline.id).stages).find(st => st.id === candidature.hs_pipeline_stage);
                        if (candidature.hs_pipeline_stage === candidature.hs_pipeline_final) {
                            cantBeUpdated = true;
                        } else {
                            var finalStage = hspipelines.find(pip => pip.id === candidature.pipeline.id).stages.find(st => st.id === candidature.pipeline.end);
                            if (stage) {
                                cantBeUpdated = stage.displayOrder >= finalStage.displayOrder;
                            } else {
                                cantBeUpdated = true;
                            }
                        }
                        return <CandidatureCard key={candidatureId} candidature={candidature} candidatureId={candidatureId} stage={stage ? stage.label : (candidature.hs_pipeline_stage ? candidature.hs_pipeline_stage : null)} cantBeUpdated={cantBeUpdated} />
                    })}
                </div>
            </div>
        </section> : null}
        {myCandidatures?.length && dataFiltered?.length ? <hr className="my-[4rem] border-gray-300" /> : null}


        {candidatures && candidatures.length ?
            <section aria-labelledby="quick-links-title">
                <div className="flex items-center px-2 md:px-0 mb-2 mt-4">
                    <div className="flex-auto">
                        <h1 style={{ fontSize: "1.5rem", lineHeight: "2rem", fontWeight: 600, marginBottom: ".5rem" }}>{t("Candidatures")}</h1>
                    </div>
                </div>
                <FilterBarCandidature candidatures={candidatures} setFilteredCandidatures={setFilteredData} />
                <div className="mt-4">
                    <h2 className="sr-only" id="quick-links-title">
                        {t("Candidatures")}
                    </h2>
                    {loadingCandidature ? <FakeList length={3} component={FakeCard} /> : (dataFiltered && dataFiltered.length ?
                        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">{dataFiltered.map((candidature, ckey) => (
                            <div key={ckey} className="bg-white rounded-md shadow-md p-4">
                                <h2 className="font-medium text-gray-900 text-lg">{candidature.parameters?.find(pa => pa.name === "name")?.value}</h2>
                                <div className="mt-2 flex gap-1">{candidature.parameters?.filter(pa => pa.name !== "description" && pa.name !== "name" && pa.value !== null && pa.value !== "" && pa.display)?.map(p => <Badge color="indigo">
                                    {p.label} : {p.value}
                                </Badge>)}</div>
                                <div className="w-full flex justify-center mt-6">
                                    <Button className="cursor-pointer" onClick={() => navigate("/candidatures/" + candidature.id)}>
                                        Candidater
                                    </Button>
                                </div>
                            </div>
                        ))}</div>
                        : <div className="text-center w-full col-span-2 py-8">

                            <svg className="mx-auto h-12 w-12 text-gray-400"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                            </svg>

                            <h3 className="mt-2 text-sm font-medium text-gray-900">{t("No forms")}</h3>
                        </div>)}
                </div>
            </section> : null}
    </div> : <></>

}

export default withTranslation()(AllCandidatures);