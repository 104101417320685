import React from "react";
import { withTranslation } from 'react-i18next';
import HandleErrorsMessage from "../../pages/Candidature/HandleErrorsMessage";
import handleCondition from "./generateForm/handleConditionOnQuestion";
import { getQuestionRender, initResponse } from "./generateForm/questionRender";
import generateYupObject from "./generateForm/yupObject";
import { FieldGroup, Fieldset } from "../../components/catalyst-component/fieldset";
import { Button } from "../../components/catalyst-component/button";
import { Text } from "../../components/catalyst-component/text";

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            form: null,
            formData: {},
            conditionInTheForm: [],
            pressSubmitOnce: false,
            submitOnLoading: false
        }
        this.generateYupObject = generateYupObject.bind(this);
        this.getQuestionRender = getQuestionRender.bind(this);
        this.initResponse = initResponse.bind(this);
        this.onDataChange = this.onDataChange.bind(this);
    }

    componentDidMount() {
        this.initForm();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.form !== this.props.form) {
            this.initForm();
        }
    }

    initForm() {
        let form = Object.assign([], this.props.form);
        form = form.filter(q => q.fieldType !== "custom input" || q.objectId === "Logic")
        if (form && form.length) {

            var conditionInTheForm = [];
            for (var index = 0; index < form.length; index++) {
                var question = form[index];
                if (question) {
                    var questionName = question.name
                    if (question.conditional && question.conditionalList) {
                        for (var clIndex in question.conditionalList) {
                            var cl = question.conditionalList[clIndex]
                            var name = null
                            if (cl.block !== "") {
                                name = form.find(q => q.id === cl.block)?.name
                            }
                            for (var sInd in cl.subs) {
                                var s = cl.subs[sInd];
                                conditionInTheForm.push({
                                    parent: name ? name : questionName,
                                    question: s,
                                    condition: cl
                                })
                            }
                        }
                    }
                }
            }
            this.schema = this.generateYupObject(form, null, conditionInTheForm, this.props.t);
            this.setState({ conditionInTheForm })
        }
        this.setState({ form: this.props.form }, () => {
            this.setState({ formData: this.handleDataChange({}) },
                () => this.setState({ loading: false }));

        });
    }

    onDataChange(formData, currentQuestion) {
        const that = this;
        if (that.state.conditionInTheForm && that.state.conditionInTheForm.find(c => c.parent === currentQuestion.name)) {
            var form = Object.assign([], that.props.form);
            form = form.filter(q => q.fieldType !== "custom input")
            that.schema = that.generateYupObject(form, formData, this.state.conditionInTheForm, this.props.t);
            that.setState({ formData: that.handleDataChange(formData) });
        } else {
            this.setState({ formData })
        }
        if (currentQuestion.required && that.state.pressSubmitOnce) {
            that.schema.validate(that.state.formData, { abortEarly: false })
                .catch(function (err) {
                    return err;
                })
                .then(err => that.setState({ errors: err }));
        }
    }

    handleDataChange(formData) {
        var form = Object.assign([], this.props.form);
        form = form.filter(q => q.fieldType !== "custom input")
        if (form && form.length) {
            for (var index = 0; index < form.length; index++) {
                var question = form[index];
                var formDataPart = this.handleQuestionValueChange(formData, question);
                for (let indexKey = 0; indexKey < Object.keys(formDataPart).length; indexKey++) {
                    var key = Object.keys(formDataPart)[indexKey];
                    formData[key] = formDataPart[key];
                }
            }
        }
        return formData;
    }

    handleQuestionValueChange(formData, question) {
        if (!handleCondition(formData, question, this.state.conditionInTheForm)) {
            delete formData[question.name];
        } else {
            if (!formData[question.name] && formData[question.name] !== 0) {
                var initValue = this.initResponse(question);
                if (initValue !== null) {
                    formData[question.name] = initValue;
                }
            }
        }
        return formData;
    }

    handleSubmitForm() {
        this.setState({ submitOnLoading: true });
        this.setState({ pressSubmitOnce: true });
        this.setState({ errors: null })
        const that = this;
        if (this.props.form.find(f => f.fieldType !== "custom input")) {
            const formData = that.state.formData;
            that.schema.isValid(formData).then(valid => {
                if (valid) {
                    this.props.handleSubmit(formData).catch(error => {
                        var errors = {};
                        errors["errors"] = [error.toString()]
                        window.scrollTo(0, 0);
                        that.setState({ errors, submitOnLoading: false });
                    });
                }
                else {
                    that.schema.validate(formData, { abortEarly: false })
                        .catch(function (err) {
                            window.scrollTo(0, 0);
                            return err;
                        })
                        .then(err => {
                            that.setState({ errors: err.errors, submitOnLoading: false })
                        });
                }
            });
        } else {
            this.props.handleSubmit({}).catch(error => {
                var errors = {};
                errors["errors"] = [error.toString()]
                window.scrollTo(0, 0);
                that.setState({ errors, submitOnLoading: false });
            });
        }
    }

    render() {
        const { t } = this.props;
        return (<>{this.state.loading ? <></> :
            <form>
                <Fieldset className="main">
                    <HandleErrorsMessage errors={this.state.errors} />
                    <div className="pb-5 gap-2" key="questionsrender">
                        <FieldGroup>
                            <div>
                                {this.state.form && this.state.form.length ? (
                                    this.state.form.map((question, key) => {
                                        return this.getQuestionRender(question, key, this.onDataChange, this.props.formId, this.props.dealId, this.props.msalInstance, this.props.currentStep, this.props.setStep);
                                    })
                                ) : null
                                }
                                <div key="submitform mt-7">
                                    <div className="flex items-center gap-4">
                                        {this.props.currentStep > 0 && <Text
                                            className="cursor-pointer"
                                            onClick={() => {
                                                this.props.setStep(this.props.currentStep - 1)
                                            }}
                                        >
                                            {t("Précédent")}
                                        </Text>}
                                        <Button
                                            type="button"
                                            className="cursor-pointer w-full"
                                            loading={this.props.loading}
                                            onClick={() => {
                                                this.handleSubmitForm()
                                            }}
                                        >
                                            {t("Save")}
                                        </Button>
                                    </div>
                                </div>
                            </div>

                        </FieldGroup>
                    </div>

                </Fieldset>
            </form>
        }</>);
    }

}

export default withTranslation()(Form);